.teaser {
  @apply py-8 md:py-24;
  @apply flex justify-center items-center;
}

.teaser__content {
  @apply w-11/12 md:w-3/4 rounded-3xl p-8 md:p-12;
  @apply flex flex-col items-center justify-center;
  @apply bg-white bg-opacity-95;
}

.teaser__headline {
  @apply text-3xl md:text-4xl text-balance font-semibold text-primary text-center pb-7 md:pb-10 w-full md:w-3/4;
}

.teaser__text {
  @apply text-lg;
  color: #333;
}
