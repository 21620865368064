.storefinderTable {
  @apply my-8 md:my-12 mx-4 font-inter xl:mx-24;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.storefinderTable__table {
  @apply w-full sm:w-3/4 md:w-full;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storefinderTable__table__items {
  @apply w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8 place-items-center;
}

.storefinderTable__table__header {
  font-size: 2rem;
}

.storefinderTable__table__item {
  @apply flex flex-col p-4 gap-2;
  width: 100%;
}

.storefinderTable__table__item__key {
  text-align: end;
}
