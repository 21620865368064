.checkout-page-wrapper {
  @apply w-full md:pt-60 pt-24 md:px-24 px-[16.5px] md:pb-24 pb-12 font-inter;
}
.checkout-page-content {
  @apply w-full grid grid-cols-1 md:gap-12 gap-6;
}
.checkout-content-wrapper {
  @apply xl:w-[calc((100%-48px)*0.68)] lg:w-[calc((100%-48px)*0.6)] w-full;
}
.checkout-action-panel-wrapper {
  @apply xl:w-[calc((100%-48px)*0.32)] lg:w-[calc((100%-48px)*0.4)] w-full;
}
.location-info,
.address-info {
  @apply pl-6;
}
.address-dropdown-wrapper {
  @apply lg:w-[465px] w-full;
}
.property-wrapper {
  @apply w-[168px];
  @apply w-[168px] flex items-center;
}
.checkout-product-image {
  @apply w-[82px] h-[82px];
}
.checkout-actions {
  display: flex;
  justify-content: space-around;
}
.location-info .home-pin {
  @apply h-[20px] !pl-[-1.5rem];
}
.address-grid {
  display: grid;
  grid-template-columns: 50px 1fr !important;
  align-items: center;
}
.billing-information {
  @apply p-6 border border-quartz rounded;
}
.edit-button {
  color: dodgerBlue;
}

.fitToModal {
  width: 80vw;
  height: 303px;
}

.deferredPaymentBox {
  width: 50vw;
  margin: auto;
  position: relative;
}

.approversDropdown {
  order: 1;
  flex: 0 0 50%;
  padding-right: 10px;
}

.approvalComment {
  order: 2;
  flex: 0 0 50%;
  padding-left: 10px;
}

.approvalDialog {
  padding: 20px;
}

.approvalDialogContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.approvalDialogFooter {
  order: 3;
  margin-left: auto;
  display: flex;

  .large-primary-btn {
    margin-right: 0;
  }

  .gray-button {
    color: white !important;
    background-color: lightgray !important;
    margin-left: 0;
    margin-right: 1rem;
  }

  .gray-button:hover {
    background-color: gainsboro !important;
  }
}

.approvalErrorBox {
  background-color: rgba(243, 3, 3, 0.05);
  border: 1px solid rgba(243, 3, 3, 1);
  color: rgba(243, 3, 3, 1);
  padding: 10px;
}
