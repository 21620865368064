.heading2 {
  @apply text-[32px] leading-[48px] font-bold;
}
.heading3 {
  @apply text-[24px] leading-[40px] font-bold;
}
.heading4 {
  @apply text-[20px] leading-[32px] font-medium;
}
.heading5 {
  @apply text-[16px] leading-[24px] font-medium;
}
.text-bold-8 {
  @apply text-[16px] leading-[20px] font-medium;
}
.text-bold-9 {
  @apply text-[12px] leading-[20px] font-medium;
}
.text-bold-10 {
  @apply text-[24px] leading-[40px] font-medium;
}
.text-regular-3 {
  @apply text-[16px] leading-[22px] font-normal;
}
.text-bold-2 {
  @apply text-[16px] leading-[20px] font-bold;
}
.text-bold-1 {
  @apply text-[24px] leading-[32px] font-semibold;
}
.text-bold-3 {
  @apply text-[16px] leading-[24px] font-bold;
}
.text-bold-4 {
  @apply text-[14px] leading-[18px] font-semibold;
}
.text-bold-5 {
  @apply text-[14px] leading-[24px] font-bold;
}
.text-regular-1 {
  @apply text-[16px] leading-[22px] font-normal;
}
.text-regular {
  @apply text-[16px] leading-[24px] font-normal;
}
.text-regular-2 {
  @apply text-[12px] leading-[18px] font-normal;
}
.text-regular-4 {
  @apply text-[12px] leading-[16px] font-normal;
}

.text-regular-5 {
  @apply text-[24px] leading-[40px] font-normal;
}
.text-regular-6 {
  @apply text-[16px] leading-[20px] font-normal;
}
.text-regular-7 {
  @apply text-[14px] leading-[16px] font-normal;
}
.text-regular-8 {
  @apply text-[20px] leading-[30px] font-normal;
}
.text-bold-11 {
  @apply text-[20px] leading-[30px] font-bold;
}
.text-bold-12 {
  @apply text-[20px] leading-[32px] font-bold;
}
.text-bold-6 {
  @apply text-[12px] leading-[16px] font-bold;
}
.text-bold-7 {
  @apply text-[12px] leading-[20px] font-bold;
}
.underline-normal-text {
  @apply underline underline-offset-4;
}
