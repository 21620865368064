@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-bgGradient;
  margin: 0;
  min-width: 375px;
  background-blend-mode: screen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
  @apply w-full h-fit md:h-14 z-20 fixed md:bg-primary sm:pb-2 md:px-4 xl:px-24  flex justify-between items-center xxl:px-[20%];
}

.home_category {
  @apply w-full text-eerieBlack py-8 lg:py-12 xl:py-20 px-4 md:px-6 xl:px-36;
}

.desktop_only {
  @apply hidden md:block;
}

.desktop_only_flex {
  @apply hidden md:flex;
}

.mobile_only {
  @apply md:!hidden;
}

.mobile_only_flex {
  @apply md:hidden flex;
}

.home_about {
  @apply lg:flex w-full text-white lg:justify-end pt-0 lg:!pt-36;
  height: 100dvh;
}

.home_product {
  @apply bg-white w-full h-[704px] md:h-[746px]  text-black;
}

.subscribe {
  @apply w-full h-fit text-center text-white;
}

.footer {
  @apply w-full h-fit;
}

.content-wrapper {
  @apply flex lg:space-x-6;
}

.login_container {
  @apply w-full h-[100dvh];
}

@layer components {
  .fs-content-wrapper {
    @apply xxl:px-[20%];
  }
  .fs-content-wrapper > * {
    @apply mt-6 mx-4 md:mx-24;
  }
  .fs-content-wrapper .mx-adjust {
    @apply md:mx-24;
  }
  .fs-content-product > * {
    @apply mt-6;
  }
  .fs-content-product .mx-adjust {
    @apply md:mx-0;
  }

  .fs-content-wrapper {
    @apply mb-10;
  }
}
li.fs-text_faq p {
  @apply pt-2;
}

.page-wrapper {
  @apply md:pt-60 pt-0 px-4 md:px-20 pb-12;
}

.page-content {
  @apply max-w-screen-xl mx-auto;
}

.home_cat_content {
  @apply grid grid-cols-1 lg:grid-cols-3 lg:gap-12 gap-y-8 px-8 mx-auto xl:pt-8;
  place-items: center;
}

.signup_container {
  @apply w-full h-[1980px];
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.invalid-tenant-page {
  @apply w-full text-darkGray items-center h-screen  text-center;
}

.rotatable {
  border-radius: 2px;
  transition: all 2s linear;
}

.rotatable.rotate {
  transform: rotate(180deg);
}

.subscribe {
  position: relative;
  overflow: hidden;
  background-color: black;
  background-image: url('/src/assets/winewithredbackground.svg');
  background-repeat: no-repeat;
  background-position: right 0px;
  background-size: 50% 150%;
}

.subscribe::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  margin-top: -100px;
}

.close-button {
  height: 20px;
  color: #8e9099;
  rotate: 45deg;
  text-align: right;
  width: 100%;
  cursor: pointer;
}

@layer components {
  .cta-primary,
  .cta-button {
    @apply cursor-pointer rounded-xl bg-primary hover:bg-highlight;
    @apply text-aliceBlue text-sm whitespace-nowrap uppercase;
    @apply overflow-hidden text-ellipsis;
    @apply px-6 md:px-10 py-4 max-w-full;
    @apply transition-all duration-150 ease-in;
  }

  .cta-primary-md {
    @apply cursor-pointer rounded-xl bg-primary hover:bg-highlight;
    @apply text-aliceBlue text-sm whitespace-nowrap uppercase;
    @apply overflow-hidden text-ellipsis;
    @apply px-4 py-2 max-w-full;
    @apply transition-all duration-150 ease-in;
  }

  .cta-secondary {
    @apply cursor-pointer rounded border border-eerieBlack bg-transparent hover:text-highlight hover:border-highlight;
    @apply text-eerieBlack text-sm whitespace-nowrap uppercase;
    @apply overflow-hidden text-ellipsis;
    @apply px-6 md:px-10 py-4 max-w-full;
    @apply transition-all duration-150 ease-in;
  }

  .cta-secondary-md {
    @apply cursor-pointer rounded border border-eerieBlack bg-transparent hover:text-highlight hover:border-highlight;
    @apply text-eerieBlack text-sm whitespace-nowrap uppercase;
    @apply overflow-hidden text-ellipsis;
    @apply px-4 py-2 max-w-full;
    @apply transition-all duration-150 ease-in;
  }
}

/* .cta-button {
  @apply curosr-pointer rounded-xl
  cursor: pointer;
  border-radius: 0.75rem !important;
  color: #1f1f1f !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
} */

/* .cta-button:hover {
  background-color: #e03f58 !important;
} */

.cta-button-checked {
  border-radius: 4px !important;
  background-color: #219653 !important;
  color: white !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  cursor: pointer;
}

input[type='checkbox'] {
  accent-color: #e9154b;
}

.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorDefault.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorDefault.Mui-checked.MuiRadio-root.MuiRadio-colorDefault
  span {
  background-color: #e9154b !important;
}

.grid-column-title {
  @apply !text-manatee text-sm;
}

.bordered-container {
  @apply border rounded border-quartz;
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
    contrast(102%);
}

.home_category_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.standard_box_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.hp_teaser_box_shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.hp_teaser_box_shadow_white {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.main_bg_gradient {
  background-image: radial-gradient(73% 147%, #eadfdf 59%, #ece2df 100%),
    radial-gradient(91% 146%, rgba(255, 255, 255, 0.5) 47%, rgba(0, 0, 0, 0.5) 100%);
  background-blend-mode: screen;
}

.tooltipped {
  @apply relative;
}

.tooltip {
  @apply hidden xs:inline-block xs:invisible absolute top-1/2 left-0 -translate-y-1/2;
}

.tooltipped:hover .tooltip {
  @apply xs:visible z-50;
}
