.content-link {
    flex: 1;
    margin: 0 10px;
}

.content-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    transition: box-shadow 0.3s ease-in-out;
}

.content-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content-card h2 {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.content-card p {
    color: #666;
    font-size: 1em;
    line-height: 1.4;
}
