.header-mega_dropdown-content {
  @apply rounded-xl mt-10;
  height: 300px;
  position: absolute;
  width: 55%;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  background-color: #ccc;
  height: 250px;
}

.column a {
  float: none;
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.column a:hover {
  background-color: #ddd;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

input[type='search'] {
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  text-indent: 20px;
}

input[type='search']::placeholder {
  color: white;
  padding-left: 20px;
}

button[type='submit']:hover {
  opacity: 1;
}

button[type='submit']:focus,
input[type='search']:focus {
  outline: none;
}

input.nosubmit {
  border: 1px solid white;
  border-radius: 20px;

  height: 40px;
  padding: 9px 4px 9px 40px;
  background: #87abbf url('../../assets/search.svg') no-repeat 20px center;
}

.nav-background-title {
  background: url('../../assets/nav_bg.png') no-repeat;
  background-size: 100% 100%;
}

.overlay_topbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.4s;
}

.overlay_topbar.visible {
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.4s;
  z-index: 1;
}

.mega_menu_dropbtn {
  @apply flex pt-2 pr-2 md:pr-8 xl:pr-12 hover:text-manatee text-eerieBlack;
}

.mega_menu_dropbtn:last-of-type {
  @apply !pr-0;
}

.mega_content_bg {
  @apply bg-gradient-to-b bg-lightBlue bg-blueGray;
}

.mega_content_category_li {
  @apply pb-6 hover:text-manatee whitespace-nowrap;
}

.mega_content_sub_cat_li {
  @apply pb-4 hover:text-manatee text-[16px]/[24px] whitespace-nowrap;
}

input.nosubmit {
  @apply rounded border-s placeholder-manatee text-eerieBlack border-manatee;
}

input::-webkit-search-cancel-button {
  @apply !bg-eerieBlack;
}

.world-icon {
  background: transparent url('../../assets/world.svg') no-repeat center;

  & + span {
    margin-left: 1rem;
  }
}
