.technical-logo {
    border-radius: 5px;
}

.technical-wrapper {
    justify-content: center;
    display: flex;
}

.technical-element {
 padding: 1rem;
}
