.quantity-input {
  @apply flex justify-center items-center w-fit;
}

.quantity-input_modifier,
.quantity-input_screen {
  user-select: none;
  outline: none;
}

.quantity-input_modifier {
  padding: 0.75rem;
  cursor: pointer;
}

.quantity-input_screen {
  @apply bg-aliceBlue w-8 text-center py-1 px-0 border-none;
}
.quantity-input {
  @apply bg-aliceBlue align-middle text-[20px] leading-[24px] !font-normal;
}

.quantity-input .MuiInput-underline::before {
  border: none;
}

.quantity-input .MuiInput-underline::after {
  border: none;
}

.quantity-input
  .MuiTextField-root
  .MuiInputBase-root:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.quantity-input input {
  text-align: center;
}
