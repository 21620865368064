.video-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

video {
  min-width: 100%;
  z-index: 1;
}

.product-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  position: relative;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: backwards;
}

.product-container.showOverlay {
  visibility: visible;
  opacity: 1;
  height: 100%;
  top: 0;
  transition: all 0.5s;
}

.product-container.hideOverlay {
  visibility: hidden;
  opacity: 0;
  height: 0;
  top: -10rem;
  transition: all 0.5s;
}
