li.item {
  @apply w-full h-12 p-3 text-eerieBlack leading-5;
}

li.item-active {
  @apply rounded bg-primary text-aliceBlue;

  img {
    filter: brightness(100);
  }
}

.left-menu-panel {
  @apply flex-auto w-full lg:w-[calc((100%-24px)*0.23)];
}

.content-panel {
  @apply flex-auto w-full lg:w-[calc((100%-24px)*0.77+24px)];
}

/* TODO: Check following entries again */

.border-bottom-gray {
  @apply border-lightGray border-b border-solid;
}

.personal-title,
.change-password-title {
  @apply w-[200px] gap-6;
}

.personal-info-catpion,
.change-password-info-catpion {
  @apply text-[20px] leading-[30px];
}

.personal-provide-caption,
.change-password-provide-caption {
  @apply font-normal text-[14px] leading-[21px];
}

.personal-photo {
  @apply w-24 h-24 rounded-[48px];
}

.personal-info-content,
.change-password-info-content {
  @apply gap-4 w-full;
}

.form-item {
  @apply align-middle sm:flex;
}

.form-input-item {
  @apply h-[38px] w-full sm:w-[250px] md:w-[200px] lg:w-[250px] xl:w-[438px] 2xl:w-[438px] rounded py-[7px] px-[13px];
}

.border-gray {
  @apply border-lightGray border border-solid;
}

.input-label {
  @apply align-middle pt-[7px];
}

.action-save-button {
  @apply mt-6 sm:mt-0 h-12 w-full sm:w-[327px] bg-tinBlue text-[white] font-inter font-bold text-[14px] leading-[14px];
}

.action-discard-button {
  @apply mt-6 sm:mt-0 h-12 w-full sm:w-[327px] text-tinBlue font-inter font-bold text-[14px] leading-[14px] bg-bgWhite;
}

.personal-and-summary-content-wrapper {
  @apply pb-4;
}

.account-card {
  @apply bg-aliceBlue rounded-xl p-4 standard-box-shadow;
}

.account-caption {
  @apply text-2xl md:text-lg lg:text-xl xl:text-2xl font-bold text-tinBlue;
}

.account-cta {
  @apply cursor-pointer font-semibold text-sm text-dodgerBlue;
}

.profile-edit-btn-arrow {
  @apply font-black leading-[16.1px];
}

.location_item {
  @apply mb-6 border border-lightGray p-6 font-inter h-60 md:h-40 md:flex;
}

.location-title {
  @apply font-medium text-base text-darkGray;
}

.location-data {
  @apply md:font-normal text-base text-black md:pt-4;
}

.SavedCarts-Mobile-Header {
  @apply py-[5px] mb-[10px] font-bold;
}

.label {
  @apply text-[20px] leading-[30px];
}

.company-input-wrapper,
.company-info-wrapper {
  @apply md:w-[63%] w-full;
}

.company-input {
  @apply w-full rounded h-[38px] py-[7px] px-[13px] font-normal;
}

.company-label {
  @apply md:w-[37%] w-full;
}

.sub-label {
  @apply font-normal text-[14px] leading-[21px];
}

.company-info {
  @apply xl:w-[61%] md:w-[40%] w-full;
}

.mobile-bar {
  background-image: conic-gradient(from 180deg at 50% 70%, #9bb9ca, #2c5c77);
  @apply h-[72px] w-full p-6 font-inter font-semibold text-[24px] leading-[24px] pb-6 text-white;
}

.switch-item {
  @apply !p-0 !w-[45px] !h-[24px] !rounded-[12px] !border border-lightGray;
}

.switch-item .MuiButtonBase-root {
  @apply !p-0 !w-4 !h-4 bg-lightGray !rounded-[12px];
}

.switch-item .MuiSwitch-thumb {
  @apply !p-0 !w-4 !h-4 bg-lightGray !rounded-[12px];
}

.switch-item .MuiSwitch-track {
  @apply hidden;
}

.switch-item .MuiButtonBase-root {
  @apply m-[3px];
}

.switch-item .Mui-checked .MuiSwitch-thumb {
  @apply bg-primary;
}

.switch-item:has(.Mui-checked) {
  @apply bg-lightGray border-primary;
}
