/* .main_page-image {
  background: url('../../assets/mainpage.svg') no-repeat center;
  height: 36rem;
} */
.home_about {
    /*background: url('../../assets/mainpage.png') no-repeat;*/
    /* height: 36rem;
    margin-top: 6rem; */
    background-size: cover;
  }
